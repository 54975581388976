.swf3d-header {
    padding-top: 0px;
}

.swf3d-header-subTitle {
    justify-content: end;
    font-size: 14px;
    margin-bottom: 4px;
}

.swf3d-quick-access-toolbar {
    &.aw-command-bar.aw-commands-commandBarHorizontal .aw-commands-commandIconButton {
        width: 24px !important;
        height: auto !important;
        padding: 0 !important; 
    }
}
