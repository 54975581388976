.my-test-navigator {
    .details-view {
        .sw-section-content {
            height: calc(100% - 30px);
            overflow: auto;
        }
    }
    .aw-panelSection {
        border-color: transparent;
    }
    .aw-panelSection {
        ::marker {
            color: #787878;
        }
        .sw-sectionTitle {
            color: #005f87 !important;
        }
    }
}
