.center {
    position: absolute;
    left: 50%;
    top: 40%;
}

.spinner-text {
    position: absolute;
    top: 35%;
    left: 50%;
    width: 128px;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
}

.loading-spinner {
    display: inline-block;
    position: relative;
    width: 128px;
    height: 128px;
}

.loading-spinner div {
    box-sizing: border-box;
    position: absolute;
    display: block;
    width: 128px;
    height: 128px;
    border: 16px solid #0077a1;
    border-radius: 50%;
    animation: loading-spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #0077a1 transparent transparent transparent;
}

.loading-spinner div:nth-child(1) {
    animation-delay: -0.45s;
}

.loading-spinner div:nth-child(2) {
    animation-delay: -0.3s;
}

.loading-spinner div:nth-child(3) {
    animation-delay: -0.15s;
}

@keyframes loading-spinner {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
