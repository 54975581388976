.info-labels {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    word-break: break-word;

    span:first-child {
        font-weight: bold;
        width: 50%;
    }

    span:last-child {
        width: 50%;
    }
}

.info-preview {
    width: 96px;
    height: 96px;
}
